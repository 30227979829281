<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button type="primary" @click="confirmForm">{{ $t('button.preservation') }}</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
    <!--主体内容-->
    <div class="bgFFF paddingT20 paddingLR20">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="165px"
        label-position="right"
      >
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="发票平台:" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio :label="0">航信</el-radio>
                <el-radio :label="2">航信诺诺</el-radio>
                <el-radio :label="3">诺诺数电</el-radio>
                <el-radio :label="1">百旺</el-radio>
                <el-radio :label="4">百旺云</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商户名称:" prop="operationId">
              <el-select v-model="ruleForm.operationId" filterable>
                <el-option
                  v-for="item in operationList"
                  :key="item.operationId"
                  :label="item.operationName"
                  :value="item.operationId"
                />
              </el-select>
            </el-form-item>
            <!-- 航信 -->
            <div v-if="ruleForm.type === 0">
              <div class="content">
                <el-form-item label="开票项目:" prop="invoiceItem">
                  <el-select v-model="ruleForm.invoiceItem">
                    <el-option
                      v-for="item in businessOrderType"
                      :key="item.code"
                      :label="item.itemName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="订单类型" prop="businessType">
                  <el-checkbox-group v-model="ruleForm.businessType">
                    <el-checkbox label="0">停车支付订单</el-checkbox>
                    <el-checkbox label="3">停车卡订单</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="计税方式:" prop="taxMode">
                  <el-select v-model="ruleForm.taxMode">
                    <el-option label="一般纳税人" value="0" />
                    <el-option label="小规模纳税人" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="税率（%）:" prop="taxRate">
                  <el-select v-model="ruleForm.taxRate">
                    <el-option
                      v-for="(item, index) in invoiceResource"
                      :key="index"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="含税标志:" prop="includeTax">
                  <el-select v-model="ruleForm.includeTax">
                    <el-option label="不含税" value="0" />
                    <el-option label="含税" value="1" />
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="平台编码:" prop="username">
                <el-input
                  v-model="ruleForm.username"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入平台编码"
                />
              </el-form-item>
              <el-form-item label="授权码:" prop="authorizationCode">
                <el-input
                  v-model="ruleForm.authorizationCode"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入授权码"
                />
              </el-form-item>
              <el-form-item label="请求地址:" prop="requestUrl">
                <el-input
                  v-model="ruleForm.requestUrl"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入请求地址"
                />
              </el-form-item>
              <el-form-item label="请求码:" prop="requestCode">
                <el-input
                  v-model="ruleForm.requestCode"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入请求码"
                />
              </el-form-item>
              <el-form-item label="请求函数:" prop="requestMethod">
                <el-input
                  v-model="ruleForm.requestMethod"
                  maxlength="40"
                  show-word-limit
                  placeholder="请输入请求函数"
                />
              </el-form-item>
              <el-form-item label="目标命名空间:" prop="targetNamespace">
                <el-input
                  v-model="ruleForm.targetNamespace"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入目标命名空间"
                />
              </el-form-item>
            </div>
            <!-- 百旺 -->
            <div v-if="ruleForm.type === 1">
              <div class="content">
                <el-form-item label="开票项目:" prop="invoiceItem">
                  <el-select v-model="ruleForm.invoiceItem">
                    <el-option
                      v-for="item in businessOrderType"
                      :key="item.code"
                      :label="item.itemName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="订单类型" prop="businessType">
                  <el-checkbox-group v-model="ruleForm.businessType">
                    <el-checkbox label="0">停车支付订单</el-checkbox>
                    <el-checkbox label="3">停车卡订单</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="计税方式:" prop="taxMode">
                  <el-select v-model="ruleForm.taxMode">
                    <el-option label="一般纳税人" value="0" />
                    <el-option label="小规模纳税人" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="税率（%）:" prop="taxRate">
                  <el-select v-model="ruleForm.taxRate">
                    <el-option
                      v-for="(item, index) in invoiceResource"
                      :key="index"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="含税标志:" prop="includeTax">
                  <el-select v-model="ruleForm.includeTax">
                    <el-option label="不含税" value="0" />
                    <el-option label="含税" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="征税方式:" prop="taxationMethod">
                  <el-select v-model="ruleForm.taxationMethod">
                    <el-option label="普通征税" value="0" />
                    <el-option label="差额征税" value="2" />
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="登录税号:" prop="username">
                <el-input
                  v-model="ruleForm.username"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入登录税号"
                />
              </el-form-item>
              <el-form-item label="APPKEY:" prop="appKey">
                <el-input
                  v-model="ruleForm.appKey"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入APPKEY"
                />
              </el-form-item>
              <el-form-item label="APPSECRET:" prop="appSecret">
                <el-input
                  v-model="ruleForm.appSecret"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入APPSECRET"
                />
              </el-form-item>
              <el-form-item label="请求地址:" prop="requestUrl">
                <el-input
                  v-model="ruleForm.requestUrl"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入请求地址"
                />
              </el-form-item>
              <el-form-item label="加密盐值:" prop="userSalt">
                <el-input
                  v-model="ruleForm.userSalt"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入加密盐值"
                />
              </el-form-item>
              <el-form-item label="接入码:" prop="password">
                <el-input
                  v-model="ruleForm.password"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入接入码"
                />
              </el-form-item>
              <el-form-item label="开票点编码:" prop="pointCode">
                <el-input
                  v-model="ruleForm.pointCode"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入开票点编码"
                />
              </el-form-item>
              <el-form-item label="设备类型:" prop="deviceType">
                <el-select v-model="ruleForm.deviceType">
                  <el-option label="税控服务" :value="0" />
                  <el-option label="税控盘" :value="1" />
                </el-select>
              </el-form-item>
            </div>
            <!-- 航信诺诺 -->
            <div v-if="ruleForm.type === 2">
              <div class="content">
                <el-form-item label="开票项目:" prop="invoiceItem">
                  <el-select v-model="ruleForm.invoiceItem">
                    <el-option
                      v-for="item in businessOrderType"
                      :key="item.code"
                      :label="item.itemName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="订单类型" prop="businessType">
                  <el-checkbox-group v-model="ruleForm.businessType">
                    <el-checkbox label="0">停车支付订单</el-checkbox>
                    <el-checkbox label="3">停车卡订单</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="计税方式:" prop="taxMode">
                  <el-select v-model="ruleForm.taxMode">
                    <el-option label="一般纳税人" value="0" />
                    <el-option label="小规模纳税人" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="税率（%）:" prop="taxRate">
                  <el-select v-model="ruleForm.taxRate">
                    <el-option
                      v-for="(item, index) in invoiceResource"
                      :key="index"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="含税标志:" prop="includeTax">
                  <el-select v-model="ruleForm.includeTax">
                    <el-option label="不含税" value="0" />
                    <el-option label="含税" value="1" />
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="APPKEY:" prop="appKey">
                <el-input
                  v-model="ruleForm.appKey"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入APPKEY"
                />
              </el-form-item>
              <el-form-item label="APPSECRET:" prop="appSecret">
                <el-input
                  v-model="ruleForm.appSecret"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入APPSECRET"
                />
              </el-form-item>
              <el-form-item label="请求地址:" prop="requestUrl">
                <el-input
                  v-model="ruleForm.requestUrl"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入请求地址"
                />
              </el-form-item>
              <el-form-item label="纳税人名称:" prop="taxpayerName">
                <el-input
                  v-model="ruleForm.taxpayerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入纳税人名称"
                />
              </el-form-item>
              <el-form-item label="纳税人识别号:" prop="taxpayerNumber">
                <el-input
                  v-model="ruleForm.taxpayerNumber"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入纳税人识别号"
                />
              </el-form-item>
            </div>
            <!-- 诺诺数电 -->
            <div v-if="ruleForm.type === 3">
              <div class="content">
                <el-form-item label="开票项目:" prop="invoiceItem">
                  <el-select v-model="ruleForm.invoiceItem">
                    <el-option
                      v-for="item in businessOrderType"
                      :key="item.code"
                      :label="item.itemName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="订单类型" prop="businessType">
                  <el-checkbox-group v-model="ruleForm.businessType">
                    <el-checkbox label="0">停车支付订单</el-checkbox>
                    <el-checkbox label="3">停车卡订单</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="计税方式:" prop="taxMode">
                  <el-select v-model="ruleForm.taxMode">
                    <el-option label="一般纳税人" value="0" />
                    <el-option label="小规模纳税人" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="税率（%）:" prop="taxRate">
                  <el-select v-model="ruleForm.taxRate">
                    <el-option
                      v-for="(item, index) in invoiceResource"
                      :key="index"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="含税标志:" prop="includeTax">
                  <el-select v-model="ruleForm.includeTax">
                    <el-option label="不含税" value="0" />
                    <el-option label="含税" value="1" />
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="APPKEY:" prop="appKey">
                <el-input
                  v-model="ruleForm.appKey"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入APPKEY"
                />
              </el-form-item>
              <el-form-item label="APPSECRET:" prop="appSecret">
                <el-input
                  v-model="ruleForm.appSecret"
                  maxlength="36"
                  show-word-limit
                  placeholder="请输入APPSECRET"
                />
              </el-form-item>
              <el-form-item label="请求地址:" prop="requestUrl">
                <el-input
                  v-model="ruleForm.requestUrl"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入请求地址"
                />
              </el-form-item>
              <el-form-item label="请求令牌:" prop="token">
                <el-input
                  v-model="ruleForm.token"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入请求令牌"
                />
              </el-form-item>
              <el-form-item label="纳税人名称:" prop="taxpayerName">
                <el-input
                  v-model="ruleForm.taxpayerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入纳税人名称"
                />
              </el-form-item>
              <el-form-item label="纳税人识别号:" prop="taxpayerNumber">
                <el-input
                  v-model="ruleForm.taxpayerNumber"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入纳税人识别号"
                />
              </el-form-item>
              <el-form-item label="不动产地址:" prop="detailAddress">
                <!-- <div style="display:flex">
                  <el-form-item label="" prop="province">
                    <el-select v-model="ruleForm.province" placeholder="请选择" style="margin: 0 10px 10px 0;">
                      <el-option
                        v-for="(item,index) in provinces"
                        @click.native ="onSelectProvince(index)"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="city">
                    <el-select v-model="ruleForm.city" placeholder="请选择" style="margin: 0 10px 10px 0;">
                      <el-option
                        v-for="(item,index) in citys"
                        @click.native ="onSelectCity(index)"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="area">
                    <el-select v-model="ruleForm.area" placeholder="请选择" style="margin: 0 10px 10px 0;">
                      <el-option
                        v-for="item in areas"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div> -->
                <el-form-item style="margin-bottom: 5px" label="" prop="realPropertyAddress">
                  <el-input
                    v-model="ruleForm.realPropertyAddress"
                    maxlength="120"
                    show-word-limit
                    placeholder="请输入省/市/区(县)"
                  />
                </el-form-item>
                <el-input
                  v-model="ruleForm.detailAddress"
                  maxlength="120"
                  show-word-limit
                  placeholder="请输入详细地址"
                />
              </el-form-item>
              <!-- prop="crossCityFlag" -->
              <el-form-item label="是否跨市:">
                <el-select v-model="ruleForm.crossCityFlag" placeholder="请选择">
                  <el-option
                    v-for="item in crossCityFlagList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="租赁时间:" prop="rentDate">
                <el-date-picker
                  v-model="ruleForm.rentDate"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="产权证书/不动产权证书:">
                <el-input
                  v-model="ruleForm.realPropertyCertificate"
                  maxlength="40"
                  show-word-limit
                  placeholder="请输入产权证书/不动产权证书"
                />
              </el-form-item>
              <el-form-item label="面积单位:">
                <el-select v-model="ruleForm.unit" placeholder="请选择">
                  <el-option
                    v-for="item in unitList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分机号:" prop="extensionNumber">
                <el-input v-model="ruleForm.extensionNumber" placeholder="请输入分机号" />
              </el-form-item>
            </div>
            <!-- 百旺云 -->
            <div v-if="ruleForm.type === 4">
              <div class="content">
                <el-form-item label="开票项目:" prop="invoiceItem">
                  <el-select v-model="ruleForm.invoiceItem">
                    <el-option
                      v-for="item in businessOrderType"
                      :key="item.code"
                      :label="item.itemName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="订单类型" prop="businessType">
                  <el-checkbox-group v-model="ruleForm.businessType">
                    <el-checkbox label="0">停车支付订单</el-checkbox>
                    <el-checkbox label="3">停车卡订单</el-checkbox>
                    <el-checkbox label="9">商家优惠券订单</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="计税方式:" prop="taxMode">
                  <el-select v-model="ruleForm.taxMode">
                    <el-option label="一般纳税人" value="0" />
                    <el-option label="小规模纳税人" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="税率（%）:" prop="taxRate">
                  <el-select v-model="ruleForm.taxRate">
                    <el-option
                      v-for="(item, index) in invoiceResource"
                      :key="index"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="含税标志:" prop="includeTax">
                  <el-select v-model="ruleForm.includeTax">
                    <el-option label="不含税" value="0" />
                    <el-option label="含税" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="征税方式:" prop="taxationMethod">
                  <el-select v-model="ruleForm.taxationMethod">
                    <el-option label="普通征税" value="0" />
                    <el-option label="差额征税" value="2" />
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="企业私钥:" prop="companyPriKey">
                <el-input
                  v-model="ruleForm.companyPriKey"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入企业私钥"
                />
              </el-form-item>
              <el-form-item label="平台公钥:" prop="platFormPubKey">
                <el-input
                  v-model="ruleForm.platFormPubKey"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入平台公钥"
                />
              </el-form-item>
              <el-form-item label="开票终端标识:" prop="invoiceTerminalCode">
                <el-input
                  v-model="ruleForm.invoiceTerminalCode"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入开票终端标识"
                />
              </el-form-item>
              <el-form-item label="机器编号:" prop="machineCode">
                <el-input
                  v-model="ruleForm.machineCode"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入机器编号"
                />
              </el-form-item>
              <el-form-item label="企业税号:" prop="enterpriseTaxCode">
                <el-input
                  v-model="ruleForm.enterpriseTaxCode"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入企业税号"
                />
              </el-form-item>
              <el-form-item label="企业代码:" prop="enterpriseCode">
                <el-input
                  v-model="ruleForm.enterpriseCode"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入企业代码"
                />
              </el-form-item>
              <el-form-item label="请求地址:" prop="requestUrl">
                <el-input
                  v-model="ruleForm.requestUrl"
                  maxlength="200"
                  show-word-limit
                  placeholder="请输入请求地址"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <!-- 航信 -->
            <div v-if="ruleForm.type === 0">
              <el-form-item label="纳税人名称:" prop="taxpayerName">
                <el-input
                  v-model="ruleForm.taxpayerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入纳税人名称"
                />
              </el-form-item>
              <el-form-item label="纳税人识别号:" prop="taxpayerNumber">
                <el-input
                  v-model="ruleForm.taxpayerNumber"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入纳税人识别号"
                />
              </el-form-item>
              <el-form-item label="销货方名称:" prop="sellerName">
                <el-input
                  v-model="ruleForm.sellerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方名称"
                />
              </el-form-item>
              <el-form-item label="销货方识别号:" prop="sellerTaxpayerNumber">
                <el-input
                  v-model="ruleForm.sellerTaxpayerNumber"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入销货方识别号"
                />
              </el-form-item>
              <el-form-item label="销货方地址:" prop="sellerAddress">
                <el-input
                  v-model="ruleForm.sellerAddress"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方地址"
                />
              </el-form-item>
              <el-form-item label="销货方电话:" prop="sellerPhoneNumber">
                <el-input
                  v-model="ruleForm.sellerPhoneNumber"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方电话"
                />
              </el-form-item>
              <el-form-item label="销货方银行名称:" prop="sellerBank">
                <el-input
                  v-model="ruleForm.sellerBank"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方银行名称"
                />
              </el-form-item>
              <el-form-item label="销货方银行账号:" prop="sellerBankAccount">
                <el-input
                  v-model="ruleForm.sellerBankAccount"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方银行账号"
                />
              </el-form-item>
              <el-form-item label="收款人:" prop="payee">
                <el-input
                  v-model="ruleForm.payee"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入收款人姓名"
                />
              </el-form-item>
              <el-form-item label="复核:" prop="auditor">
                <el-input
                  v-model="ruleForm.auditor"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入复核人姓名"
                />
              </el-form-item>
              <el-form-item label="开票人:" prop="drawer">
                <el-input
                  v-model="ruleForm.drawer"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入开票人姓名"
                />
              </el-form-item>
              <el-form-item label="最小开票金额:" prop="minimumAmount">
                <el-input
                  v-model="ruleForm.minimumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最小开票金额（0.01~1000）"
                />
              </el-form-item>
              <el-form-item label="最大开票金额:" prop="maximumAmount">
                <el-input
                  v-model="ruleForm.maximumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最大开票金额（0.01~1000）"
                />
              </el-form-item>
            </div>
            <!-- 百旺 -->
            <div v-if="ruleForm.type === 1">
              <el-form-item v-if="ruleForm.deviceType === 1" label="税控盘编号:" prop="deviceCode">
                <el-input
                  v-model="ruleForm.deviceCode"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入税控盘编号"
                />
              </el-form-item>
              <el-form-item label="销货方名称:" prop="sellerName">
                <el-input
                  v-model="ruleForm.sellerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方名称"
                />
              </el-form-item>
              <el-form-item label="销货方识别号:" prop="sellerTaxpayerNumber">
                <el-input
                  v-model="ruleForm.sellerTaxpayerNumber"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入销货方识别号"
                />
              </el-form-item>
              <el-form-item label="销货方地址:" prop="sellerAddress">
                <el-input
                  v-model="ruleForm.sellerAddress"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方地址"
                />
              </el-form-item>
              <el-form-item label="销货方电话:" prop="sellerPhoneNumber">
                <el-input
                  v-model="ruleForm.sellerPhoneNumber"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方电话"
                />
              </el-form-item>
              <el-form-item label="销货方银行名称:" prop="sellerBank">
                <el-input
                  v-model="ruleForm.sellerBank"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方银行名称"
                />
              </el-form-item>
              <el-form-item label="销货方银行账号:" prop="sellerBankAccount">
                <el-input
                  v-model="ruleForm.sellerBankAccount"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方银行账号"
                />
              </el-form-item>
              <el-form-item label="收款人:" prop="payee">
                <el-input
                  v-model="ruleForm.payee"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入收款人姓名"
                />
              </el-form-item>
              <el-form-item label="复核:" prop="auditor">
                <el-input
                  v-model="ruleForm.auditor"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入复核人姓名"
                />
              </el-form-item>
              <el-form-item label="开票人:" prop="drawer">
                <el-input
                  v-model="ruleForm.drawer"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入开票人姓名"
                />
              </el-form-item>
              <el-form-item label="最小开票金额:" prop="minimumAmount">
                <el-input
                  v-model="ruleForm.minimumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最小开票金额（0.01~1000）"
                />
              </el-form-item>
              <el-form-item label="最大开票金额:" prop="maximumAmount">
                <el-input
                  v-model="ruleForm.maximumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最大开票金额（0.01~1000）"
                />
              </el-form-item>
              <el-form-item label="是否开启票量提醒:" prop="needRemind">
                <el-radio-group v-model="ruleForm.needRemind">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="ruleForm.needRemind === 1">
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="服务器编号:"
                  prop="serverNumber"
                >
                  <el-input
                    v-model="ruleForm.serverNumber"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入服务器编号"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="开票终端标识:"
                  prop="terminalId"
                >
                  <el-input
                    v-model="ruleForm.terminalId"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入开票终端标识"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税控盘口令:"
                  prop="deviceToken"
                >
                  <el-input
                    v-model="ruleForm.deviceToken"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税控盘口令"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税务数字证书密码:"
                  prop="devicePassword"
                >
                  <el-input
                    v-model="ruleForm.devicePassword"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税务数字证书密码"
                  />
                </el-form-item>
                <el-form-item label="提醒票量:" prop="remindTicketVolume">
                  <el-input
                    v-model="ruleForm.remindTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动提醒的发票数量"
                  />
                </el-form-item>
                <el-form-item label="告警票量:" prop="warningTicketVolume">
                  <el-input
                    v-model="ruleForm.warningTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动告警的发票数量"
                  />
                </el-form-item>
                <el-form-item label="短信通知:" prop="messageReceiver">
                  <el-input
                    v-model="ruleForm.messageReceiver"
                    maxlength="60"
                    show-word-limit
                    placeholder="请输入需要票量通知的手机号，多个手机号可用 ； 区隔"
                  />
                </el-form-item>
                <el-form-item label="邮件通知:" prop="emailReceiver">
                  <el-input
                    v-model="ruleForm.emailReceiver"
                    maxlength="100"
                    show-word-limit
                    placeholder="请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔"
                  />
                </el-form-item>
              </template>
            </div>
            <!-- 航信诺诺 -->
            <div v-if="ruleForm.type === 2">
              <el-form-item v-if="ruleForm.deviceType === 1" label="税控盘编号:" prop="deviceCode">
                <el-input
                  v-model="ruleForm.deviceCode"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入税控盘编号"
                />
              </el-form-item>
              <el-form-item label="销货方名称:" prop="sellerName">
                <el-input
                  v-model="ruleForm.sellerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方名称"
                />
              </el-form-item>
              <el-form-item label="销货方识别号:" prop="sellerTaxpayerNumber">
                <el-input
                  v-model="ruleForm.sellerTaxpayerNumber"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入销货方识别号"
                />
              </el-form-item>
              <el-form-item label="销货方地址:" prop="sellerAddress">
                <el-input
                  v-model="ruleForm.sellerAddress"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方地址"
                />
              </el-form-item>
              <el-form-item label="销货方电话:" prop="sellerPhoneNumber">
                <el-input
                  v-model="ruleForm.sellerPhoneNumber"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方电话"
                />
              </el-form-item>
              <el-form-item label="销货方银行名称:" prop="sellerBank">
                <el-input
                  v-model="ruleForm.sellerBank"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方银行名称"
                />
              </el-form-item>
              <el-form-item label="销货方银行账号:" prop="sellerBankAccount">
                <el-input
                  v-model="ruleForm.sellerBankAccount"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方银行账号"
                />
              </el-form-item>
              <el-form-item label="收款人:" prop="payee">
                <el-input
                  v-model="ruleForm.payee"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入收款人姓名"
                />
              </el-form-item>
              <el-form-item label="复核:" prop="auditor">
                <el-input
                  v-model="ruleForm.auditor"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入复核人姓名"
                />
              </el-form-item>
              <el-form-item label="开票人:" prop="drawer">
                <el-input
                  v-model="ruleForm.drawer"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入开票人姓名"
                />
              </el-form-item>
              <el-form-item label="最小开票金额:" prop="minimumAmount">
                <el-input
                  v-model="ruleForm.minimumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最小开票金额（0.01~1000）"
                />
              </el-form-item>
              <el-form-item label="最大开票金额:" prop="maximumAmount">
                <el-input
                  v-model="ruleForm.maximumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最大开票金额（0.01~1000）"
                />
              </el-form-item>
              <template v-if="ruleForm.needRemind === 1">
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="服务器编号:"
                  prop="serverNumber"
                >
                  <el-input
                    v-model="ruleForm.serverNumber"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入服务器编号"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="开票终端标识:"
                  prop="terminalId"
                >
                  <el-input
                    v-model="ruleForm.terminalId"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入开票终端标识"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税控盘口令:"
                  prop="deviceToken"
                >
                  <el-input
                    v-model="ruleForm.deviceToken"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税控盘口令"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税务数字证书密码:"
                  prop="devicePassword"
                >
                  <el-input
                    v-model="ruleForm.devicePassword"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税务数字证书密码"
                  />
                </el-form-item>
                <el-form-item label="提醒票量:" prop="remindTicketVolume">
                  <el-input
                    v-model="ruleForm.remindTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动提醒的发票数量"
                  />
                </el-form-item>
                <el-form-item label="告警票量:" prop="warningTicketVolume">
                  <el-input
                    v-model="ruleForm.warningTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动告警的发票数量"
                  />
                </el-form-item>
                <el-form-item label="短信通知:" prop="messageReceiver">
                  <el-input
                    v-model="ruleForm.messageReceiver"
                    maxlength="60"
                    show-word-limit
                    placeholder="请输入需要票量通知的手机号，多个手机号可用 ； 区隔"
                  />
                </el-form-item>
                <el-form-item label="邮件通知:" prop="emailReceiver">
                  <el-input
                    v-model="ruleForm.emailReceiver"
                    maxlength="100"
                    show-word-limit
                    placeholder="请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔"
                  />
                </el-form-item>
              </template>
            </div>
            <!-- 诺诺数电 -->
            <div v-if="ruleForm.type === 3">
              <!-- <el-form-item v-if="ruleForm.deviceType === 1" label="税控盘编号:" prop="deviceCode">
                <el-input
                  v-model="ruleForm.deviceCode"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入税控盘编号"
                />
              </el-form-item> -->
              <el-form-item label="销货方名称:" prop="sellerName">
                <el-input
                  v-model="ruleForm.sellerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方名称"
                />
              </el-form-item>
              <el-form-item label="销货方识别号:" prop="sellerTaxpayerNumber">
                <el-input
                  v-model="ruleForm.sellerTaxpayerNumber"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入销货方识别号"
                />
              </el-form-item>
              <el-form-item label="销货方地址:" prop="sellerAddress">
                <el-input
                  v-model="ruleForm.sellerAddress"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方地址"
                />
              </el-form-item>
              <el-form-item label="销货方电话:" prop="sellerPhoneNumber">
                <el-input
                  v-model="ruleForm.sellerPhoneNumber"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方电话"
                />
              </el-form-item>
              <el-form-item label="销货方银行名称:" prop="sellerBank">
                <el-input
                  v-model="ruleForm.sellerBank"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方银行名称"
                />
              </el-form-item>
              <el-form-item label="销货方银行账号:" prop="sellerBankAccount">
                <el-input
                  v-model="ruleForm.sellerBankAccount"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方银行账号"
                />
              </el-form-item>
              <el-form-item label="收款人:" prop="payee">
                <el-input
                  v-model="ruleForm.payee"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入收款人姓名"
                />
              </el-form-item>
              <el-form-item label="复核:" prop="auditor">
                <el-input
                  v-model="ruleForm.auditor"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入复核人姓名"
                />
              </el-form-item>
              <el-form-item label="开票人:" prop="drawer">
                <el-input
                  v-model="ruleForm.drawer"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入开票人姓名"
                />
              </el-form-item>
              <el-form-item label="最小开票金额:" prop="minimumAmount">
                <el-input
                  v-model="ruleForm.minimumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最小开票金额（0.01~1000）"
                />
              </el-form-item>
              <el-form-item label="最大开票金额:" prop="maximumAmount">
                <el-input
                  v-model="ruleForm.maximumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最大开票金额（0.01~1000）"
                />
              </el-form-item>
              <template v-if="ruleForm.needRemind === 1">
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="服务器编号:"
                  prop="serverNumber"
                >
                  <el-input
                    v-model="ruleForm.serverNumber"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入服务器编号"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="开票终端标识:"
                  prop="terminalId"
                >
                  <el-input
                    v-model="ruleForm.terminalId"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入开票终端标识"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税控盘口令:"
                  prop="deviceToken"
                >
                  <el-input
                    v-model="ruleForm.deviceToken"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税控盘口令"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税务数字证书密码:"
                  prop="devicePassword"
                >
                  <el-input
                    v-model="ruleForm.devicePassword"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税务数字证书密码"
                  />
                </el-form-item>
                <el-form-item label="提醒票量:" prop="remindTicketVolume">
                  <el-input
                    v-model="ruleForm.remindTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动提醒的发票数量"
                  />
                </el-form-item>
                <el-form-item label="告警票量:" prop="warningTicketVolume">
                  <el-input
                    v-model="ruleForm.warningTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动告警的发票数量"
                  />
                </el-form-item>
                <el-form-item label="短信通知:" prop="messageReceiver">
                  <el-input
                    v-model="ruleForm.messageReceiver"
                    maxlength="60"
                    show-word-limit
                    placeholder="请输入需要票量通知的手机号，多个手机号可用 ； 区隔"
                  />
                </el-form-item>
                <el-form-item label="邮件通知:" prop="emailReceiver">
                  <el-input
                    v-model="ruleForm.emailReceiver"
                    maxlength="100"
                    show-word-limit
                    placeholder="请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔"
                  />
                </el-form-item>
              </template>
            </div>
            <!-- 百旺云 -->
            <div v-if="ruleForm.type === 4">
              <el-form-item v-if="ruleForm.deviceType === 1" label="税控盘编号:" prop="deviceCode">
                <el-input
                  v-model="ruleForm.deviceCode"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入税控盘编号"
                />
              </el-form-item>
              <el-form-item label="销货方名称:" prop="sellerName">
                <el-input
                  v-model="ruleForm.sellerName"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方名称"
                />
              </el-form-item>
              <el-form-item label="销货方识别号:" prop="sellerTaxpayerNumber">
                <el-input
                  v-model="ruleForm.sellerTaxpayerNumber"
                  maxlength="30"
                  show-word-limit
                  placeholder="请输入销货方识别号"
                />
              </el-form-item>
              <el-form-item label="销货方地址:" prop="sellerAddress">
                <el-input
                  v-model="ruleForm.sellerAddress"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方地址"
                />
              </el-form-item>
              <el-form-item label="销货方电话:" prop="sellerPhoneNumber">
                <el-input
                  v-model="ruleForm.sellerPhoneNumber"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入销货方电话"
                />
              </el-form-item>
              <el-form-item label="销货方银行名称:" prop="sellerBank">
                <el-input
                  v-model="ruleForm.sellerBank"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入销货方银行名称"
                />
              </el-form-item>
              <el-form-item label="销货方银行账号:" prop="sellerBankAccount">
                <el-input
                  v-model="ruleForm.sellerBankAccount"
                  maxlength="23"
                  show-word-limit
                  placeholder="请输入销货方银行账号"
                />
              </el-form-item>
              <el-form-item label="收款人:" prop="payee">
                <el-input
                  v-model="ruleForm.payee"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入收款人姓名"
                />
              </el-form-item>
              <el-form-item label="复核:" prop="auditor">
                <el-input
                  v-model="ruleForm.auditor"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入复核人姓名"
                />
              </el-form-item>
              <el-form-item label="开票人:" prop="drawer">
                <el-input
                  v-model="ruleForm.drawer"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入开票人姓名"
                />
              </el-form-item>
              <el-form-item label="最小开票金额:" prop="minimumAmount">
                <el-input
                  v-model="ruleForm.minimumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最小开票金额（0.01~1000）"
                />
              </el-form-item>
              <el-form-item label="最大开票金额:" prop="maximumAmount">
                <el-input
                  v-model="ruleForm.maximumAmount"
                  maxlength="8"
                  show-word-limit
                  placeholder="请输入最大开票金额（0.01~1000）"
                />
              </el-form-item>
              <!-- <el-form-item label="是否开启票量提醒:" prop="needRemind">
                <el-radio-group v-model="ruleForm.needRemind">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <template v-if="ruleForm.needRemind === 1">
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="服务器编号:"
                  prop="serverNumber"
                >
                  <el-input
                    v-model="ruleForm.serverNumber"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入服务器编号"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 0"
                  label="开票终端标识:"
                  prop="terminalId"
                >
                  <el-input
                    v-model="ruleForm.terminalId"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入开票终端标识"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税控盘口令:"
                  prop="deviceToken"
                >
                  <el-input
                    v-model="ruleForm.deviceToken"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税控盘口令"
                  />
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.deviceType === 1"
                  label="税务数字证书密码:"
                  prop="devicePassword"
                >
                  <el-input
                    v-model="ruleForm.devicePassword"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入税务数字证书密码"
                  />
                </el-form-item>
                <el-form-item label="提醒票量:" prop="remindTicketVolume">
                  <el-input
                    v-model="ruleForm.remindTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动提醒的发票数量"
                  />
                </el-form-item>
                <el-form-item label="告警票量:" prop="warningTicketVolume">
                  <el-input
                    v-model="ruleForm.warningTicketVolume"
                    maxlength="30"
                    show-word-limit
                    placeholder="请输入系统自动告警的发票数量"
                  />
                </el-form-item>
                <el-form-item label="短信通知:" prop="messageReceiver">
                  <el-input
                    v-model="ruleForm.messageReceiver"
                    maxlength="60"
                    show-word-limit
                    placeholder="请输入需要票量通知的手机号，多个手机号可用 ； 区隔"
                  />
                </el-form-item>
                <el-form-item label="邮件通知:" prop="emailReceiver">
                  <el-input
                    v-model="ruleForm.emailReceiver"
                    maxlength="100"
                    show-word-limit
                    placeholder="请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔"
                  />
                </el-form-item>
              </template>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import address from "./address.json";
export default {
  data() {
    let checkTel = (rule, value, callback) => {
      var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
      var isMob = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (isMob.test(value) || isPhone.test(value)) {
        callback();
      } else {
        callback("销货方电话格式不正确");
      }
    };
    return {
      provinces: address,
      citys: [],
      areas: [],
      ruleForm: {
        type: 0,
        deviceType: 0,
        needRemind: 0,
        operationId: "",
        invoiceItem: 1,
        businessType: [],
        taxMode: "",
        taxRate: "",
        includeTax: "1",
        username: "",
        authorizationCode: "",
        requestUrl: "",
        requestCode: "",
        requestMethod: "",
        targetNamespace: "",
        taxationMethod: "",
        appKey: "",
        appSecret: "",
        userSalt: "",
        password: "",
        pointCode: "",
        taxpayerName: "",
        taxpayerNumber: "",
        sellerName: "",
        sellerTaxpayerNumber: "",
        sellerAddress: "",
        sellerPhoneNumber: "",
        sellerBank: "",
        sellerBankAccount: "",
        payee: "",
        auditor: "",
        drawer: "",
        minimumAmount: "",
        maximumAmount: "",
        serverNumber: "",
        terminalId: "",
        deviceToken: "",
        devicePassword: "",
        remindTicketVolume: "",
        warningTicketVolume: "",
        messageReceiver: "",
        emailReceiver: "",
        deviceCode: "",
        // province: '',
        // city: '',
        // area: '',
        detailAddress: "",
        realPropertyAddress: "",
        crossCityFlag: "否",
        rentDate: [], // [dateFormat(end, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
        realPropertyCertificate: "无",
        unit: "平方米",

        token: "", // 请求令牌
        extensionNumber: "", // 分机号，一般为'99'或'100'
        companyPriKey: "",
        platFormPubKey: "",
        enterpriseCode: "",
        invoiceTerminalCode: "",
        machineCode: "",
        enterpriseTaxCode: "",
      },
      rules: {
        type: [{ required: true, message: "请选择发票平台", trigger: "change" }],
        operationId: [{ required: true, message: "请选择商户名称", trigger: "change" }],
        invoiceItem: [{ required: true, message: "请选择开票项目", trigger: "change" }],
        businessType: [{ required: true, message: "请选择订单类型", trigger: "change" }],
        taxMode: [{ required: true, message: "请选择计税方式", trigger: "change" }],
        taxRate: [{ required: true, message: "请选择税率", trigger: "change" }],
        includeTax: [{ required: true, message: "请选择含税标志", trigger: "change" }],
        username: [{ required: true, message: "请填写此项信息", trigger: "blur" }],
        authorizationCode: [{ required: true, message: "请填写授权码", trigger: "blur" }],
        requestUrl: [{ required: true, message: "请填写请求地址", trigger: "blur" }],
        requestCode: [{ required: true, message: "请填写请求码", trigger: "blur" }],
        requestMethod: [{ required: true, message: "请填写请求函数", trigger: "blur" }],
        targetNamespace: [{ required: true, message: "请填写目标命名空间", trigger: "blur" }],
        taxpayerName: [{ required: true, message: "请填写纳税人名称", trigger: "blur" }],
        taxpayerNumber: [{ required: true, message: "请填写纳税人识别号", trigger: "blur" }],
        sellerName: [{ required: true, message: "请填写销货方名称", trigger: "blur" }],
        sellerTaxpayerNumber: [{ required: true, message: "请填写销货方识别号", trigger: "blur" }],
        sellerAddress: [{ required: true, message: "请填写销货方地址", trigger: "blur" }],
        sellerPhoneNumber: [
          { required: true, message: "请填写销货方电话", trigger: "change" },
          { validator: checkTel, trigger: "change" },
        ],
        sellerBank: [{ required: true, message: "请填写销货方银行名称", trigger: "blur" }],
        sellerBankAccount: [{ required: true, message: "请填写销货方银行账号", trigger: "blur" }],
        payee: [{ required: true, message: "请填写收款人姓名", trigger: "blur" }],
        auditor: [{ required: true, message: "请填写复核人姓名", trigger: "blur" }],
        drawer: [{ required: true, message: "请填写开票人姓名", trigger: "blur" }],
        taxationMethod: [{ required: true, message: "请选择征税方式", trigger: "change" }],
        appKey: [{ required: true, message: "请填写APPKEY", trigger: "blur" }],
        appSecret: [{ required: true, message: "请填写APPSECRET", trigger: "blur" }],
        userSalt: [{ required: true, message: "请填写加密盐值", trigger: "blur" }],
        password: [{ required: true, message: "请填写接入码", trigger: "blur" }],
        pointCode: [{ required: true, message: "请填写开票点编码", trigger: "blur" }],
        deviceType: [{ required: true, message: "请选择设备类型", trigger: "change" }],
        needRemind: [{ required: true, message: "请选择是否开启票量提醒", trigger: "change" }],
        deviceCode: [{ required: true, message: "请填写税控盘编号", trigger: "blur" }],
        minimumAmount: [{ pattern: /^\d+$|^\d+[.]?\d+$/, message: "最小开票金额格式有误" }],
        maximumAmount: [{ pattern: /^\d+$|^\d+[.]?\d+$/, message: "最大开票金额格式有误" }],
        serverNumber: [{ required: true, message: "请填写服务器编号", trigger: "blur" }],
        terminalId: [{ required: true, message: "请填写开票终端标识", trigger: "blur" }],
        deviceToken: [{ required: true, message: "请填写税控盘口令", trigger: "blur" }],
        devicePassword: [{ required: true, message: "请填写税务数字证书密码", trigger: "blur" }],
        remindTicketVolume: [{ required: true, message: "请填写提醒票量", trigger: "blur" }],
        warningTicketVolume: [{ required: true, message: "请填写告警票量", trigger: "blur" }],
        messageReceiver: [{ required: true, message: "请填写短信通知", trigger: "blur" }],
        emailReceiver: [{ required: true, message: "请填写邮件通知", trigger: "blur" }],
        detailAddress: [{ required: true, message: "请填写详细地址", trigger: "blur" }],
        crossCityFlag: [{ required: true, message: "请选择是否跨市", trigger: "change" }],
        rentDate: [{ required: true, message: "请选择租赁时间", trigger: "change" }],
        extensionNumber: [{ required: true, message: "请填写分机号", trigger: "blur" }],
        // province: [{ required: true, message: "", trigger: "change" }],
        // city: [{ required: true, message: "", trigger: "change" }],
        // area: [{ required: true, message: "", trigger: "change" }],

        companyPriKey: [{ required: true, message: "请填写企业私钥", trigger: "blur" }],
        platFormPubKey: [{ required: true, message: "请填写平台公钥", trigger: "blur" }],
        enterpriseCode: [{ required: true, message: "请填写企业代码", trigger: "blur" }],
        invoiceTerminalCode: [{ required: true, message: "请填写开票终端标识", trigger: "blur" }],
        machineCode: [{ required: true, message: "请填写机器编码", trigger: "blur" }],
        enterpriseTaxCode: [{ required: true, message: "请填写企业税号", trigger: "blur" }],
        token: [{ required: true, message: "请填写请求令牌", trigger: "blur" }],
      },
      operationList: [],
      invoiceResource: [],
      businessOrderType: [],
      invoiceSettingId: this.$route.query.invoiceSettingId,
      crossCityFlagList: [
        { value: "否", lebel: "0" },
        { value: "是", lebel: "1" },
      ],
      unitList: [
        { value: "平方千米", lebel: "1" },
        { value: "平方米", lebel: "2" },
        { value: "公顷", lebel: "3" },
        { value: "亩", lebel: "4" },
        { value: "hm²", lebel: "5" },
        { value: "km²", lebel: "6" },
        { value: "m²", lebel: "7" },
      ],
    };
  },
  mounted() {
    Promise.all([this.getOperation(), this.getInvoiceResource()]).then((res) => {
      if (this.invoiceSettingId) {
        // 编辑环境
        this.getFormDetail();
      }
    });
  },
  methods: {
    onSelectProvince(idx) {
      this.citys = this.provinces[idx].children ?? [];
      this.areas = [];
      this.ruleForm.city = "";
      this.ruleForm.area = "";
    },
    onSelectCity(idx) {
      this.areas = this.citys[idx].children ?? [];
      this.ruleForm.area = "";
    },
    // 获取发票配置详情
    getFormDetail() {
      const opt = {
        url: "/acb/2.0/invoiceSetting/getById",
        method: "get",
        data: { invoiceSettingId: this.invoiceSettingId },
        success: (res) => {
          const value = res.value;
          this.ruleForm.type = value.invoiceChannel;
          this.ruleForm.operationId = value.operationId;
          this.ruleForm.invoiceItem = value.invoiceSettingItems[0].invoiceItem;
          const businessType = value.invoiceSettingItems[0].businessType.split(",");
          this.ruleForm.businessType = businessType;
          this.ruleForm.taxMode = value.invoiceSettingItems[0].taxMode + "";
          this.ruleForm.taxRate = value.invoiceSettingItems[0].taxRate + "";
          this.ruleForm.includeTax = value.invoiceSettingItems[0].includeTax + "";
          if (value.invoiceChannel === 1 || value.invoiceChannel === 4) {
            this.ruleForm.taxationMethod = value.invoiceSettingItems[0].taxationMethod + "";
            this.ruleForm.deviceType = value.expand.deviceType * 1;
          }
          this.ruleForm.username = value.expand.username;
          this.ruleForm.authorizationCode = value.expand.authorizationCode;
          this.ruleForm.requestUrl = value.expand.requestUrl;
          this.ruleForm.requestCode = value.expand.requestCode;
          this.ruleForm.requestMethod = value.expand.requestMethod;
          this.ruleForm.targetNamespace = value.expand.targetNamespace;
          this.ruleForm.appKey = value.expand.appKey;
          this.ruleForm.appSecret = value.expand.appSecret;
          this.ruleForm.userSalt = value.expand.userSalt;
          this.ruleForm.password = value.expand.password;
          this.ruleForm.pointCode = value.expand.pointCode;
          this.ruleForm.taxpayerName = value.expand.taxpayerName;
          this.ruleForm.taxpayerNumber = value.expand.taxpayerNumber;
          this.ruleForm.sellerName = value.sellerName;
          this.ruleForm.sellerTaxpayerNumber = value.sellerTaxpayerNumber;
          this.ruleForm.sellerAddress = value.sellerAddress;
          this.ruleForm.sellerPhoneNumber = value.sellerPhoneNumber;
          this.ruleForm.sellerBank = value.sellerBank;
          this.ruleForm.sellerBankAccount = value.sellerBankAccount;
          this.ruleForm.payee = value.payee;
          this.ruleForm.auditor = value.auditor;
          this.ruleForm.drawer = value.drawer;
          this.ruleForm.minimumAmount = value.minimumAmount;
          this.ruleForm.maximumAmount = value.maximumAmount;
          this.ruleForm.needRemind = value.expand.needRemind;
          this.ruleForm.serverNumber = value.expand.serverNumber;
          this.ruleForm.terminalId = value.expand.terminalId;
          this.ruleForm.deviceToken = value.expand.deviceToken;
          this.ruleForm.devicePassword = value.expand.devicePassword;
          this.ruleForm.remindTicketVolume = value.expand.remindTicketVolume;
          this.ruleForm.warningTicketVolume = value.expand.warningTicketVolume;
          this.ruleForm.messageReceiver = value.expand.messageReceiver;
          this.ruleForm.emailReceiver = value.expand.emailReceiver;

          if (value.invoiceChannel === 3) {
            this.ruleForm.detailAddress = value.expand.detailAddress;
            this.ruleForm.realPropertyAddress = value.expand.realPropertyAddress;
            this.ruleForm.crossCityFlag = value.expand.crossCityFlag == "1" ? "是" : "否";
            this.ruleForm.rentDate = [value.expand.rentStartDate, value.expand.rentEndDate];
            this.ruleForm.realPropertyCertificate = value.expand.realPropertyCertificate;
            this.ruleForm.unit = value.expand.unit
              ? this.unitList[value.expand.unit - 1].value
              : "平方米";
            this.ruleForm.token = value.expand.token;
          }
          this.ruleForm.extensionNumber = value.expand.extensionNumber; // 分机号

          this.ruleForm.companyPriKey = value.expand.companyPriKey;
          this.ruleForm.platFormPubKey = value.expand.platFormPubKey;
          this.ruleForm.enterpriseCode = value.expand.enterpriseCode;
          this.ruleForm.invoiceTerminalCode = value.expand.invoiceTerminalCode;
          this.ruleForm.machineCode = value.expand.machineCode;
          this.ruleForm.enterpriseTaxCode = value.expand.enterpriseTaxCode;
        },
      };
      this.$request(opt);
    },
    // 获取所有商户列表
    getOperation() {
      return new Promise((resolve, reject) => {
        const opt = {
          url: "/acb/2.0/operation/nameList",
          method: "get",
          data: {},
          success: (res) => {
            this.operationList = res.value;
            resolve();
          },
        };
        this.$request(opt);
      });
    },
    // 获取所有发票税率
    getInvoiceResource() {
      return new Promise((resolve, reject) => {
        const opt = {
          url: "/acb/2.0/invoiceSetting/getInvoiceResource",
          method: "get",
          data: {},
          success: (res) => {
            this.invoiceResource = res.value.taxRate;
            this.businessOrderType = res.value.invoiceItem;
            resolve();
          },
        };
        this.$request(opt);
      });
    },
    // 保存
    confirmForm() {
      const tempData = Object.assign({}, this.ruleForm);
      delete tempData.type;
      let url = "";
      if (this.ruleForm.type === 0) {
        delete tempData.deviceType;
        delete tempData.needRemind;
        if (this.invoiceSettingId) {
          url = "/acb/2.0/invoiceSetting/modify4Hx";
          tempData.invoiceSettingId = this.invoiceSettingId;
        } else {
          url = "/acb/2.0/invoiceSetting/create4Hx";
        }
      } else if (this.ruleForm.type === 2) {
        if (this.invoiceSettingId) {
          url = "/acb/2.0/invoiceSetting/nuonuo/modify";
          tempData.invoiceSettingId = this.invoiceSettingId;
        } else {
          url = "/acb/2.0/invoiceSetting/nuonuo/create";
        }
      } else if (this.ruleForm.type === 1) {
        tempData.deviceCode = tempData.deviceCode;
        if (this.invoiceSettingId) {
          url = "/acb/2.0/invoiceSetting/modify4Bw";
          tempData.invoiceSettingId = this.invoiceSettingId;
        } else {
          url = "/acb/2.0/invoiceSetting/create4Bw";
        }
      } else if (this.ruleForm.type === 3) {
        if (this.invoiceSettingId) {
          url = "/acb/2.0/invoiceSetting/nuonuoDigitalInvoice/modify";
          tempData.invoiceSettingId = this.invoiceSettingId;
        } else {
          url = "/acb/2.0/invoiceSetting/nuonuoDigitalInvoice/create";
        }
        // tempData.realPropertyAddress = tempData.province + tempData.city + tempData.area
        tempData.rentStartDate = tempData.rentDate ? tempData.rentDate[0] : "";
        tempData.rentEndDate = tempData.rentDate ? tempData.rentDate[1] : "";
        tempData.crossCityFlag = tempData.crossCityFlag === "是" ? "1" : "0";
        this.unitList.forEach((item, index) => {
          if (tempData.unit == item.value) {
            tempData.unit = item.lebel;
          }
        });
      } else if (this.ruleForm.type === 4) {
        if (this.invoiceSettingId) {
          url = "/acb/2.0/invoiceSetting/baiwangcloud/modify";
          tempData.invoiceSettingId = this.invoiceSettingId;
        } else {
          url = "/acb/2.0/invoiceSetting/baiwangcloud/create";
        }
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (tempData.minimumAmount) {
            if (tempData.minimumAmount < 0.01 || tempData.minimumAmount > 1000) {
              this.$alert("最小开票金额为0.01至1000之间", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              return;
            }
          }
          if (tempData.maximumAmount) {
            if (tempData.maximumAmount < 0.01 || tempData.maximumAmount > 1000) {
              this.$alert("最大开票金额为0.01至1000之间", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              return;
            }
          }
          tempData.businessType = this.ruleForm.businessType.join(",");
          const opt = {
            url: url,
            method: "post",
            data: tempData,
            success: (res) => {
              this.$alert("操作成功", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.$router.push({ path: "/invoiceManage" });
            },
          };
          this.$request(opt);
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
  text-align right
  margin-bottom 20px
.content
  background-color #f2f2f2
  padding 20px 0 1px
  margin-bottom 20px
</style>
